import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import APIRoute from '../routersAPI/routes.json';
import Swal from 'sweetalert2'
import { MDBDataTableV5 } from 'mdbreact';
import { FiEye} from "react-icons/fi";

import { MdDeleteForever} from "react-icons/md";
import CreateButton from './create.button';

export default function ClientList() {
    const [clientlist, setClientlists] = useState([]);
    const headCells = [
        {
            label: 'Rut',
            field: 'rut',
        },
        {
            label: 'Nombre',
            field: 'name',
        },
        {
            label: 'Telefono',
            field: 'phone',
        },
        {
            label: 'Correo',
            field: 'email_client',
        },
        {
            label: 'Estado',
            field: 'status',
        },
        {
            label: 'Acción',
            field: 'actionBtn'
        }
    ];
    const [datatable, setDatatable] = useState({
        columns: headCells,
        rows: []

    });
    useEffect(() => {
        fetchClient()
    }, [APIRoute.clients])

    async function fetchClient() {
        await axios.get(APIRoute.clients).then(({ data }) => {
            setClientlists(data);
            setDatatable({
                columns: headCells,
                rows: data.map(client => {
                    let status = "";
                    if (client.status === 1) {
                        status = "REALIZADA";

                    } else {
                        status = "PENDIENTE";
                    }
                    return {
                        id: client.id_client,
                        rut: client.rut_client,
                        name: client.name_client,
                        phone: client.phone_client,
                        email_client: client.email_client,
                        status: status,
                        actionBtn: <>
                            <div className="d-grid col-md-3 col-lg-3 col-sm-7" style={{ fontSize: '12px' }}>
                                <Link to={APIRoute.editClient + client.id_client}>
                                    <Button title="Editar" className='btn btn-success'>
                                        <FiEye style={{ margin: 0, fontSize: '20px' }} />
                                    </Button>
                                </Link>
                                <Button title="Eliminar" className='btn btn-danger' onClick={() => deleteClientlist(client.id_client)}>
                                    <MdDeleteForever style={{ margin: 0, fontSize: '20px' }} />
                                </Button>
                            </div>
                        </>
                    };
                })
            });
        });
    }
    const deleteClientlist = async (id) => {
        const isConfirm = await Swal.fire({
            title: '¿Estas seguro?',
            text: "No podras revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            return result.isConfirmed
        });

        if (!isConfirm) {
            return;
        }
        await axios.delete(APIRoute.clients + id).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            fetchClient()
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }
    return (
        <div className="container">
            <div className="row">
              <CreateButton/>
                <div className="col-12">
                    <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        searchTop searchBottom={false} searchLabel="Buscar"
                        exportToCSV
                        responsive
                        data={datatable}
                    />
                </div>
            </div>
        </div>
    )
}
