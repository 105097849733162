import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import APIRoute from "../routersAPI/routes.json";
import { Alert, Button } from "react-bootstrap";

export default function CreateAppraisal() {
  const [idAppraisal] = useState(sessionStorage.getItem("idAppraisal"));
  const [type_of_assets, setTypeOfAsset] = useState("");
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [checkListTypeOfAssetsList, setCheckListTypeOfAssetsList] = useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState({});
  const [rol, setRol] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [bedroom, setBedroom] = useState([]);
  const [terrain_area, setTerrainArea] = useState([]);
  const [construction_area, setConstructionArea] = useState([]);
  const [clientDescription, setClientDescription] = useState([]);
  const [address, setAddress] = useState("");
  const [id_commune, setCommune] = useState("");
  const [id_region, setRegion] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);

  const fetchAppraisalData = async () => {
    let dataR = "";
    axios
      .get(APIRoute.appraisal + idAppraisal)
      .then(({ data }) => {
        setAddress(data["address"]);
        setRegion(data["id_region"]);
        dataR = data["id_region"];
        setCommune(data["id_commune"]);
        setBathroom(data["bathrooms"]);
        setBedroom(data["bedrooms"]);
        setTypeOfAsset(data["id_type_of_assets"]);
        dataR = data["id_type_of_assets"];
        setRol(data["rol"]);
      })
      .finally(() => {
        axios.get(APIRoute.commune + dataR).then(({ data }) => {
          setCommuneList(data);
        });
        axios.get(APIRoute.complement + dataR).then(({ data }) => {
          setCheckListTypeOfAssetsList(data);
        });
      });
  };

  useEffect(() => {
    fetchRegionList();
    fetchAppraisalData();
    fetchTypeOfAssetList();
  }, []);

  const fetchRegionList = async () => {
    axios.get(APIRoute.region).then(({ data }) => {
      setRegionList(data);
    });
  };

  const handleCommune = async (id_commune) => {
    const formData = new FormData();
    formData.append("id", id_commune);
    await axios
      .post(APIRoute.commune, formData)
      .then(({ data }) => {
        setCommuneList(data);
      })
      .catch(({ response }) => {
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
        }
      });
  };

  const fetchTypeOfAssetList = async () => {
    axios.get(APIRoute.asset).then(({ data }) => {
      setTypeOfAssetList(data);
    });
  };

  const handleAsset = async (e) => {
    setTypeOfAsset(e);
    axios
      .get(APIRoute.complement + e)
      .then(({ data }) => {
        setCheckListTypeOfAssetsList(data);
      })
      .catch(({ response }) => {
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
        }
      });
  };

  const createAppraisal = async (temp, e) => {
    const formData = new FormData();
    formData.append("id", idAppraisal);
    formData.append("idSupervisor", 1);
    formData.append("idCommune", id_commune);
    formData.append("address", address);
    formData.append("idTypeOfAsset", type_of_assets);
    formData.append("rol", rol);
    formData.append("terrainArea", terrain_area);
    formData.append("constructionArea", construction_area);
    formData.append("bedrooms", bedroom);
    formData.append("bathrooms", bathroom);
    formData.append("clientDescription", clientDescription);
    formData.append("reportStatus", 0);

    await axios
      .post(APIRoute.edit_appraisal + idAppraisal, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response }) => {
        sessionStorage.removeItem("statusConfirm");
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
          Swal.fire({
            text: "Por favor validar todos los campos del formulario",
            icon: "error",
          });
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {
        createComplementary(temp);
      });
  };

  const createComplementary = async (temp) => {
    for (let i = 0; i < temp.length; i++) {
      let idDescComp = "";
      let quantityComplementarySender = "";
      if (temp[i][""] || temp[i]["null"]) {
        Swal.fire({
          text: "Error en complementarios",
          icon: "error",
        });
      } else {
        idDescComp = temp[i][0];
        quantityComplementarySender = temp[i][1];
        const formData = new FormData();
        formData.append("idAppraisal", idAppraisal);
        formData.append("idDescComp", idDescComp);
        formData.append("quantityComplementary", quantityComplementarySender);

        await axios
          .post(APIRoute.compcontroller, formData)
          .then(({ data }) => {})
          .catch(({ response }) => {
            if (response.status === 500) {
              setErrorMessage(response.data.errors);
            } else {
              Swal.fire({
                text: errorMessage,
                icon: "error",
              });
            }
            if (response.status === 422) {
              setErrorMessage(response.data.errors);
            } else {
              Swal.fire({
                text: errorMessage,
                icon: "error",
              });
            }
          });
      }
    }
  };

  let temp = [
    [1, "0"],
    [1, "0"],
    [1, "0"],
    [1, "0"],
  ];
  const onchangeInput = (val, index, item_id) => {
    temp[index] = [item_id, val];
    return temp;
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-12 col-md-10">
          <div className="progress my-4" style={{ height: "30px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 1"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 1
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 2"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 2
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 3"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 3
            </div>
          </div>
          <div className="card">
            <div className="card-body bg-light ">
              <h4 className="card-title">Detalles del bien</h4>
              <hr />
              <div className="form-wrapper">
                <Form onSubmit={createAppraisal}>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="address">
                        <Form.Label column sm="2">
                          Dirección
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese dirección"
                            value={address}
                            onChange={(event) => {
                              setAddress(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="region">
                        <Form.Label column sm="2">
                          Región
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            aria-label="Default select example"
                            value={id_region}
                            onChange={(event) => {
                              setRegion(event.target.value);
                              handleCommune(event.target.value);
                            }}
                          >
                            <option value="" defaultValue disabled="active">
                              Seleccione Región
                            </option>
                            {regionList.map((item, index) => (
                              <option key={index} value={item.id_region}>
                                {item.name_region}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="commune"
                        name="commune"
                      >
                        <Form.Label column sm="2">
                          Comuna
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            aria-label="Default select example"
                            value={id_commune}
                            onChange={(event) => {
                              setCommune(event.target.value);
                            }}
                          >
                            <option value="" defaultValue disabled="disabled">
                              Seleccione tipo de comuna
                            </option>
                            {communeList.map((item, index) => (
                              <option key={index} value={item.id_commune}>
                                {item.name_commune}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="rol">
                        <Form.Label column sm="2">
                          Rol
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese rol"
                            required
                            value={rol}
                            onChange={(event) => {
                              setRol(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="type_of_assets"
                      >
                        <Form.Label column sm="2">
                          Tipo de bien
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            aria-label="Default select example"
                            value={type_of_assets}
                            onChange={(event) => {
                              handleAsset(event.target.value);
                            }}
                          >
                            <option value="" defaultValue disabled="disabled">
                              Seleccione tipo de bien
                            </option>
                            {typeOfAssetList.map((item, index) => (
                              <option
                                key={index}
                                value={item.id_type_of_assets}
                              >
                                {item.description}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                          Complementos
                        </Form.Label>
                        <Col sm="9">
                          {checkListTypeOfAssetsList.map((item, index) => (
                            <Col
                              className="row alig-content-center"
                              sm="12"
                              key={index}
                            >
                              <Form.Label
                                className="col-12"
                                value={item.id_desc_comp}
                                type="label"
                                aria-label="nameComplement"
                                hidden
                              />
                              <p className="col-9 row alig-content-center">
                                {item.name_comp}
                              </p>
                              <Form.Control
                                className="col-3"
                                type="number"
                                defaultValue={0}
                                min="0"
                                required
                                aria-label="numberInput"
                                value={item.quantityComplementary}
                                onChange={(e) => {
                                  onchangeInput(
                                    e.target.value,
                                    index,
                                    item.id_desc_comp
                                  );
                                }}
                              />
                              <br /> <br />
                            </Col>
                          ))}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="">
                        <Form.Label column sm="2">
                          N° Baños
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="number"
                            size="lg"
                            placeholder="Ingrese n° baños"
                            required
                            value={bathroom}
                            onChange={(event) => {
                              setBathroom(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="bedroom">
                        <Form.Label column sm="2">
                          N° Habitaciones
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="number"
                            size="lg"
                            placeholder="Ingrese n° habitaciones"
                            required
                            value={bedroom}
                            onChange={(event) => {
                              setBedroom(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="terrain_area"
                      >
                        <Form.Label column sm="2">
                          Superficie en terreno
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="number"
                            size="lg"
                            placeholder="Ingrese superficie terreno"
                            required
                            value={terrain_area}
                            onChange={(event) => {
                              setTerrainArea(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="construction_area"
                      >
                        <Form.Label column sm="2">
                          Área en construcción
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="number"
                            size="lg"
                            placeholder="Ingrese área en construcción"
                            required
                            value={construction_area}
                            onChange={(event) => {
                              setConstructionArea(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="clientDescription"
                      >
                        <Form.Label column sm="2">
                          Descripción del bien
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            as="textarea"
                            type="text"
                            size="lg"
                            placeholder="Ingrese descripción del bien"
                            required
                            value={clientDescription}
                            onChange={(event) => {
                              setClientDescription(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Alert variant="warning">
                    Asegurese de rellenar <b>TODOS</b> los campos y revisar bien
                    los datos antes de continuar (<b>No</b> se puede volver
                    atras, asegurese de confirmar sus datos)
                  </Alert>
                  <Button
                    onClick={() => {
                      createAppraisal(temp);
                      sessionStorage.setItem("statusConfirm", true);
                    }}
                  >
                    Confirmar datos
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
