import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams, Link } from "react-router-dom";
import APIRoute from "../routersAPI/routes.json";
import Accordion from "react-bootstrap/Accordion";

export default function EditAppraisal() {
  const { id } = useParams();
  const [namePhoto, setNamePhoto] = useState([]);
  const [namePhoto2, setNamePhoto2] = useState([]);
  const [namePhoto3, setNamePhoto3] = useState([]);
  const [namePhoto4, setNamePhoto4] = useState([]);
  const [namePhoto5, setNamePhoto5] = useState([]);
  const [namePhoto6, setNamePhoto6] = useState([]);
  const [doc, setDoc] = useState([]);
  const [doc2, setDoc2] = useState([]);
  const [doc3, setDoc3] = useState([]);
  const [doc4, setDoc4] = useState([]);
  const [idSupervisor] = useState(sessionStorage.getItem("id"));
  const [idClient, setIdClient] = useState("");
  const [rut_client, setRutClient] = useState("");
  const [name_client, setNameClient] = useState("");
  const [email_client, setEmailClient] = useState("");
  const [phone_client, setPhoneClient] = useState("");
  const [address, setAddress] = useState("");
  const [idCommune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [typeOfAssets, setTypeOfAsset] = useState("");
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [checkListTypeOfAssetsList, setCheckListTypeOfAssetsList] = useState(
    []
  );
  const [errorMessage, setErrorMessage] = useState({});
  const [idTypeOfAsset, setIdTypeOfAsset] = useState("");
  const [rol, setRol] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [bedroom, setBedroom] = useState([]);
  const [terrainArea, setTerrainArea] = useState([]);
  const [constructionArea, setConstructionArea] = useState([]);
  const [descClient, setDescClient] = useState([]);
  const [reportStatus, setReportStatus] = useState([]);
  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    fetchRegionList();
    fetchAppraisalData();
    fetchTypeOfAssetList();
    fetchComplementary();
    handlePhoto();
    handleDoc();
  }, []);

  const fetchClient = async (idClientRequest) => {
    await axios.get(APIRoute.clients + idClientRequest).then(({ data }) => {
      setIdClient(idClientRequest);
      setRutClient(data.client["rut_client"]);
      setNameClient(data.client["name_client"]);
      setPhoneClient(data.client["phone_client"]);
      setEmailClient(data.client["email_client"]);
    });
  };
  const fetchComplementary = async () => {
    await axios.get(APIRoute.compcontroller + id).then(({ data }) => {
      setCheckListTypeOfAssetsList(data);
    });
  };

  const fetchRegionList = () => {
    axios.get(APIRoute.region).then(({ data }) => {
      setRegionList(data);
    });
  };
  const fetchAppraisalData = async () => {
    let dataR = "";
    await axios
      .get(APIRoute.appraisal + id)
      .then(({ data }) => {
        fetchClient(data["id_client"]);
        setAddress(data["address"]);
        setRegion(data["id_region"]);
        dataR = data["id_region"];
        setCommune(data["id_commune"]);
        setIdTypeOfAsset(data["id_type_of_assets"]);
        setBedroom(data["bedrooms"]);
        setBathroom(data["bathrooms"]);
        setRol(data["rol"]);
        setTerrainArea(data["terrain_area"]);
        setConstructionArea(data["construction_area"]);
        setDescClient(data["client_description"]);
        setReportStatus(data["reportstatus"]);
      })
      .finally(() => {
        axios.get(APIRoute.commune + dataR).then(({ data }) => {
          setCommuneList(data);
        });
      });
  };
  const handleCommune = async (idRegion) => {
    await axios
      .get(APIRoute.commune + idRegion)
      .then(({ data }) => {
        setCommuneList(data);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setErrorMessage(response.data.errors);
        }
      });
  };
  const handleAsset = async (idTypeOfAssets) => {
    setIdTypeOfAsset(idTypeOfAssets);
    await axios
      .get(APIRoute.complement + idTypeOfAssets)
      .then(({ data }) => {
        setCheckListTypeOfAssetsList(data);
      })
      .catch(({ response }) => {
        setErrorMessage(response.data.errors);
      });
  };
  const fetchTypeOfAssetList = async () => {
    await axios.get(APIRoute.asset).then(({ data }) => {
      setTypeOfAssetList(data);
    });
  };
  const updateAppraisal = async () => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("idSupervisor", idSupervisor);
    formData.append("idTypeOfAsset", idTypeOfAsset);
    formData.append("idCommune", idCommune);
    formData.append("address", address);
    formData.append("rol", rol);
    formData.append("bedrooms", bedroom);
    formData.append("bathrooms", bathroom);
    formData.append("terrainArea", terrainArea);
    formData.append("constructionArea", constructionArea);
    formData.append("reportStatus", 1);
    formData.append("clientDescription", descClient);
    await axios
      .post(APIRoute.edit_appraisal + id, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          let texto = "";
          setValidationError(response.data.errors);
          Object.entries(validationError).map(
            ([key, value]) => (texto += "\n>" + value)
          );
          Swal.fire({
            text: texto,
            icon: "error",
          });
        } else {
          let texto = "";
          texto = response.data.message;

          Swal.fire({
            text: texto,
            icon: "error",
          });
        }
      });
  };
  const handlePhoto = async (e) => {
    await axios
      .get(APIRoute.photo + id)
      .then(({ data }) => {
        setNamePhoto("http://192.168.1.117:8000/" + data[0]["photo_name"]);
        setNamePhoto2("http://192.168.1.117:8000/" + data[1]["photo_name"]);
        setNamePhoto3("http://192.168.1.117:8000/" + data[2]["photo_name"]);
        setNamePhoto4("http://192.168.1.117:8000/" + data[3]["photo_name"]);
        setNamePhoto5("http://192.168.1.117:8000/" + data[4]["photo_name"]);
        setNamePhoto6("http://192.168.1.117:8000/" + data[5]["photo_name"]);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        }
      })
      .finally(() => {});
  };
  const handleDoc = async (e) => {
    await axios
      .get(APIRoute.doc + id)
      .then(({ data }) => {
        setDoc("http://192.168.1.117:8000/" + data[0]["name_file"]);
        setDoc2("http://192.168.1.117:8000/" + data[1]["name_file"]);
        setDoc3("http://192.168.1.117:8000/" + data[2]["name_file"]);
        setDoc4("http://192.168.1.117:8000/" + data[3]["name_file"]);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        }
      })
      .finally(() => {});
  };
  const updateClientList = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("id", idClient);
    formData.append("rut", rut_client);
    formData.append("name", name_client);
    formData.append("phone", phone_client);
    formData.append("email", email_client);
    await axios
      .post(APIRoute.edit_client + id, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          let texto = "";
          setValidationError(response.data.errors);
          Object.entries(validationError).map(
            ([key, value]) => (texto += "\n>" + value)
          );
          Swal.fire({
            text: texto,
            icon: "error",
          });
        } else {
          let texto = "";
          texto = response.data.message;
          Swal.fire({
            text: texto,
            icon: "error",
          });
        }
      });
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12">
          <h4 className="card-title">Editar Datos Cliente</h4>
          <br />
          <div className="card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Datos Cliente</Accordion.Header>
                <Accordion.Body>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Datos Cliente</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={updateClientList}>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="rut_client"
                              >
                                <Form.Label column sm="2">
                                  Rut Cliente
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="ej. 12345678-9"
                                    required
                                    value={rut_client}
                                    onChange={(event) => {
                                      setRutClient(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="name_client"
                              >
                                <Form.Label column sm="2">
                                  Nombre Cliente
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese nombre cliente"
                                    required
                                    value={name_client}
                                    onChange={(event) => {
                                      setNameClient(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="phone_client"
                              >
                                <Form.Label column sm="2">
                                  Teléfono
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese Telefono"
                                    required
                                    value={phone_client}
                                    onChange={(event) => {
                                      setPhoneClient(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="email_client"
                              >
                                <Form.Label column sm="2">
                                  Correo
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese Correo"
                                    required
                                    value={email_client}
                                    onChange={(event) => {
                                      setEmailClient(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Button
                            className="mt-2"
                            size="lg"
                            block="block"
                            type="submit"
                          >
                            Actualizar
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Detalle tipo de bien</Accordion.Header>
                <Accordion.Body>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Detalle tipo de bien</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="address"
                              >
                                <Form.Label column sm="2">
                                  Dirección
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese dirección"
                                    required
                                    value={address}
                                    onChange={(event) => {
                                      setAddress(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="region"
                              >
                                <Form.Label column sm="2">
                                  Región
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Select
                                    aria-label="Seleccionar Region"
                                    value={region}
                                    onChange={(e) => {
                                      setRegion(e.target.value);
                                      handleCommune(e.target.value);
                                    }}
                                  >
                                    <option
                                      value=""
                                      defaultValue
                                      disabled="active"
                                    >
                                      Seleccione Región
                                    </option>
                                    {regionList.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.id_region}
                                      >
                                        {item.name_region}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="idCommune"
                                name="idCommune"
                              >
                                <Form.Label column sm="2">
                                  Comuna
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Select
                                    aria-label="Seleccionar comuna"
                                    value={idCommune}
                                    onChange={(event) => {
                                      setCommune(event.target.value);
                                    }}
                                  >
                                    <option
                                      value=""
                                      defaultValue
                                      disabled="disabled"
                                    >
                                      Seleccione tipo de comuna
                                    </option>
                                    {communeList.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.id_commune}
                                        onChange={() => {}}
                                      >
                                        {item.name_commune}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="idTypeOfAsset"
                              >
                                <Form.Label column sm="2">
                                  Tipo de bien
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Select
                                    aria-label="Seleccionar tipo de bien"
                                    value={idTypeOfAsset}
                                    disabled
                                  >
                                    <option
                                      value=""
                                      defaultValue
                                      disabled="disabled"
                                    >
                                      Seleccione tipo de bien
                                    </option>
                                    {typeOfAssetList.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.id_type_of_assets}
                                      >
                                        {item.description}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="fileNumber"
                              >
                                <Form.Label column sm="2">
                                  Complementos
                                </Form.Label>
                                <Col sm="10">
                                  {checkListTypeOfAssetsList.map(
                                    (item, index) => (
                                      <Col
                                        className="row alig-content-center"
                                        key={index}
                                      >
                                        <Form.Label
                                          className="col-9 col-md-9"
                                          value={item.id_desc_comp}
                                          hidden
                                        />
                                        <h6 className="col-6 row alig-content-center">
                                          {item.name_comp}
                                        </h6>
                                        <Form.Control
                                          className="col-3"
                                          type="number"
                                          disabled
                                          min="0"
                                          value={item.quantity_complementary}
                                        />
                                        <br />
                                        <br />
                                        <br />
                                      </Col>
                                    )
                                  )}
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="rol"
                              >
                                <Form.Label column sm="2">
                                  N° Baños
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    aria-label="numero de baños"
                                    type="number"
                                    size="lg"
                                    placeholder="Ingrese n° baños"
                                    required
                                    value={bathroom}
                                    onChange={(event) => {
                                      setBathroom(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="bedroom"
                              >
                                <Form.Label column sm="2">
                                  N° Habitaciones
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    aria-label="numero de habitaciones"
                                    type="number"
                                    size="lg"
                                    placeholder="Ingrese n° habitaciones"
                                    required
                                    value={bedroom}
                                    onChange={(event) => {
                                      setBedroom(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="rol"
                              >
                                <Form.Label column sm="2">
                                  Rol
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    aria-label="ingrese rol"
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese rol"
                                    required
                                    value={rol}
                                    onChange={(event) => {
                                      setRol(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="terrainArea"
                              >
                                <Form.Label column sm="2">
                                  Superficie en terreno
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese superficie terreno"
                                    required
                                    value={terrainArea}
                                    onChange={(event) => {
                                      setTerrainArea(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="constructionArea"
                              >
                                <Form.Label column sm="2">
                                  Área en construcción
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese área en construcción"
                                    required
                                    value={constructionArea}
                                    onChange={(event) => {
                                      setConstructionArea(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="my-3">
                            <Col>
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="descClient"
                              >
                                <Form.Label column sm="2">
                                  Descripción Cliente
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    as="textarea"
                                    type="text"
                                    size="lg"
                                    placeholder="Ingrese descripción del cliente"
                                    required
                                    value={descClient}
                                    onChange={(event) => {
                                      setDescClient(event.target.value);
                                    }}
                                  />
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Button
                            className="mt-2"
                            size="lg"
                            block="block"
                            onClick={updateAppraisal}
                          >
                            Actualizar Tasación
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Fotos tipo de bien</Accordion.Header>
                <Accordion.Body>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Fotos de la propiedad</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Row className="my-3">
                          <Col sm="12">
                            <Form.Group className="mb-3">
                              <Form.Label>Fachada</Form.Label>
                              <br />
                              <Image
                                style={{ width: 400, height: 300 }}
                                className="img-fluid rounded"
                                src={namePhoto}
                              />
                              <hr />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Baños</Form.Label>
                              <br />
                              <Image
                                style={{ width: 400, height: 300 }}
                                className="img-fluid rounded"
                                src={namePhoto2}
                              />
                              <hr />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Cocina</Form.Label>
                              <br />
                              <Image
                                style={{ width: 400, height: 300 }}
                                className="img-fluid rounded"
                                src={namePhoto3}
                              />
                              <hr />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Dormitorio</Form.Label>
                              <br />
                              <Image
                                style={{ width: 400, height: 300 }}
                                className="img-fluid rounded"
                                src={namePhoto4}
                              />
                              <hr />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Sala de estar</Form.Label>
                              <br />
                              <Image
                                style={{ width: 400, height: 300 }}
                                className="img-fluid rounded"
                                src={namePhoto5}
                              />
                              <hr />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Otros</Form.Label>
                              <br />
                              <Image
                                style={{ width: 400, height: 300 }}
                                className="img-fluid rounded"
                                src={namePhoto6}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Tipo Documentos</Accordion.Header>
                <Accordion.Body>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Tipo de documentos</h4>
                      <Row className="my-3">
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Escritura</h4>
                              <hr />
                              <div className="form-wrapper">
                                <Form>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Link onClick={() => openInNewTab(doc)}>
                                        Descargar
                                      </Link>
                                    </Form.Group>
                                  </Col>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Certificados Sii</h4>
                              <hr />
                              <div className="form-wrapper">
                                <Form>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Link onClick={() => openInNewTab(doc2)}>
                                        Descargar
                                      </Link>
                                    </Form.Group>
                                  </Col>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Planos</h4>
                              <hr />
                              <div className="form-wrapper">
                                <Form>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Link onClick={() => openInNewTab(doc3)}>
                                        Descargar
                                      </Link>
                                    </Form.Group>
                                  </Col>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title">Otros</h4>
                              <hr />
                              <div className="form-wrapper">
                                <Form>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Link onClick={() => openInNewTab(doc4)}>
                                        Descargar
                                      </Link>
                                    </Form.Group>
                                  </Col>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <div className="container">
                      <p>
                        Al usar este servicio acepta los{" "}
                        <a href="*"> Terminos y condiciones</a>
                      </p>
                      <br />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <Button href="/appraisal">Regresar a lista de tasaciones</Button>
        </div>
      </div>
    </div>
  );
}
