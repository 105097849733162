import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import APIRoute from "../routersAPI/routes.json";
import Button from "react-bootstrap/Button";

export default function CreateAppraisal3() {
  const [idAppraisal] = useState(sessionStorage.getItem("idAppraisal"));
  const [nameFile, setNameFile] = useState("");
  const [idArchive, setIdArchive] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const createAppraisal3 = async () => {
    const formData = new FormData();
    formData.append("id_appraisal", idAppraisal);
    formData.append("name_file", nameFile);
    formData.append("id_archive_type", idArchive);
    await axios
      .post(APIRoute.doc, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: errorMessage,
            icon: "error",
          });
        }
      })
      .finally(() => {
        usarCode(idAppraisal);
      });
  };

  const usarCode = async (idAppraisal) => {
    const formData = new FormData();
    formData.append("id_appraisal", idAppraisal);
    formData.append("statusCode", 1);
    await axios
      .post(APIRoute.usecode, formData)        
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-12 col-md-10">
          <div className="progress my-4" style={{ height: "30px" }}>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 1"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 1
            </div>
            <div
              className="progress-bar-grey"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 2"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 2
            </div>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-label="Paso 3"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Paso 3
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Tipo de documentos</h4>
              <Row className="my-3">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Escritura</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (.xls, .xlsx, .doc, .docx y .pdf)
                              </Form.Label>
                              <Form.Control
                                maxfilesize={28400000}
                                type="file"
                                name="nameFile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(1);
                                }}
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                createAppraisal3();
                              }}
                            >
                              Cargar
                            </Button>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Certificados Sii</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (.xls, .xlsx, .doc, .docx y .pdf)
                              </Form.Label>
                              <Form.Control
                                maxfilesize={28400000}
                                type="file"
                                name="nameFile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(2);
                                }}
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                createAppraisal3();
                              }}
                            >
                              Cargar
                            </Button>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Planos</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (.xls, .xlsx, .doc, .docx y .pdf)
                              </Form.Label>
                              <Form.Control
                                maxfilesize={28400000}
                                type="file"
                                name="nameFile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(3);
                                }}
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                createAppraisal3();
                              }}
                            >
                              Cargar
                            </Button>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Otros</h4>
                      <hr />
                      <div className="form-wrapper">
                        <Form onSubmit={createAppraisal3}>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Documentos (.xls, .xlsx, .doc, .docx y .pdf)
                              </Form.Label>
                              <Form.Control
                                maxfilesize={28400000}
                                type="file"
                                name="namefile"
                                onChange={(e) => {
                                  setNameFile(e.target.files[0]);
                                  setIdArchive(4);
                                }}
                                multiple
                                accept=".xls,.xlsx,.doc,.docx,.pdf"
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                createAppraisal3();
                              }}
                            >
                              Cargar
                            </Button>
                          </Col>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <div className="container">
              <p>
                Al usar este servicio acepta los{" "}
                <a href="*"> Terminos y condiciones</a>
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
