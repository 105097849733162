import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Nav, Navbar} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import logo from "../../logo.png";
import AppraisalList from '../supervisor/list.component';
import EditAppraisal from '../supervisor/edit.appraisal';

export default function SupervisorMenu() {
  return (
    <Router>
            <Navbar collapseOnSelect expand="sm" bg="primary" variant="dark">
                <Container>
                    <Navbar.Brand className="navbar-brand text-white">
                        <Link to={"/"} className="navbar-brand text-white">
                            <img src={logo} alt="Logo" style={{ bottom: '20px', right: '20px', width: '100px' }} />
                            <div style={{ fontSize: '15px' }}>Valuaciones SPA</div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-brand text-white" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Link to={"/"} className="navbar-brand text-white">
                                Home
                            </Link>
                            <Link to={"/appraisal"} className="navbar-brand text-white">
                                Tasaciones
                            </Link>

                        </Nav>
                        <Link to={"/"} className="navbar-brand text-white">
                        {sessionStorage.getItem('session') !== 0 || sessionStorage.getItem('session') != null ?
                                    <div className='logout text-white'
                                        onClick={(e) => {
                                            sessionStorage.removeItem("session");
                                            sessionStorage.removeItem('typeLogin');
                                            sessionStorage.removeItem('token');
                                            sessionStorage.removeItem('id');
                                            window.location.reload();
                                        }}
                                    >   
                                <FiLogOut style={{ margin: 10, fontSize: '20px' }} className='float-right text-white mr-auto'/> Cerrar sesión</div>
                        : <></>
                        }
                        </Link> 
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="mt-5">
                <Row>
                    <Col md={12}>
                        <Routes>
                            {/* ===========RUTAS COORDINADOR ==================== */}
                            <Route exact path="*" element={<AppraisalList/>} />
                            <Route exact path="/appraisal/edit/:id" element={<EditAppraisal/>}/>
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </Router>
    );
}