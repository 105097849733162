import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Access from "./components/access/access";
import Authentication from "./components/Auth/Authentication";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/intranet" exact element={ <Authentication />} />
        <Route path='/' element={ <Access />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;