import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import APIRoute from '../routersAPI/routes.json'

export default function CreateClient() {
    const navigate = useNavigate();
    const { id } = useParams()
    const [rut_client, setRutClient] = useState("");
    const [name_client, setNameClient] = useState("");
    const [email_client, setEmailClient] = useState("");
    const [phone_client, setPhoneClient] = useState("");
    const [type_of_assets, setTypeOfAsset] = useState("");
    const [typeOfAssetList, setTypeOfAssetList] = useState([]);
    const [commune, setCommune] = useState("");
    const [region, setRegion] = useState("");
    const [communeList, setCommuneList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [checkListTypeOfAssetsList, setCheckListTypeOfAssetsList] = useState([]);
    const [idTypeOfAssets, setIdTypeOfAssets] = useState("");
    const [rol, setRol] = useState([]);
    const [bathroom, setBathroom] = useState([]);
    const [bedroom, setBedroom] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});
    const [checkListController, setCheckListController] = useState([]);
    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchClient();
    }, [APIRoute.clients + id])

    const fetchClient = async () => {
        await axios.get(APIRoute.clients + id).then(({ data }) => {

            setRutClient(data.client['rut_client']);
            setNameClient(data.client['name_client']);
            setPhoneClient(data.client['phone_client']);
            setEmailClient(data.client['email_client']);
        })
    };
    const handleAsset = async (e) => {
        setTypeOfAsset(e)
        await axios.get(APIRoute.complement + e).then(({ data }) => {
            setCheckListTypeOfAssetsList(data);

        }).catch(({ response }) => {
            if (response.status === 422) {
                setErrorMessage(response.data.errors)
            }
        })
    }
    const handleCommune = async (e) => {
        const formData = new FormData()
        formData.append('id', e)
        await axios.post(APIRoute.commune, formData).then(({ data }) => {
            setCommuneList(data);

        }).catch(({ response }) => {
            if (response.status === 422) {
                setErrorMessage(response.data.errors)
            }
        })
    }
    const handleCheck = async (e) => {
        console.log(type_of_assets);
        setTypeOfAsset(e)
        await axios.get(APIRoute.compcontroller + e).then(({ data }) => {
            setCheckListController(data);
        }).catch(({ response }) => {
            if (response.status === 422) {
                setErrorMessage(response.data.errors)
            }
        })
    }

    const updateClientList = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('id', id)
        formData.append('rut', rut_client)
        formData.append('name', name_client)
        formData.append('phone', phone_client)
        formData.append('email', email_client)

        await axios.post(APIRoute.edit_client + id, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            navigate("/client")
        }).catch(({ response }) => {
            if (response.status === 422) {
                let texto = ""
                setValidationError(response.data.errors)
                Object.entries(validationError).map(([key, value]) => (
                    texto += "\n>" +value 
                ))
                Swal.fire({
                    text: texto,
                    icon: "error"
                })
            }
        })
    }
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Actualizar Datos Cliente</h4>
                            <hr />
                            <div className="form-wrapper">
                                <Form onSubmit={updateClientList}>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="rut_client">
                                                <Form.Label column sm="2">Rut Cliente</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Rut Cliente" required value={rut_client} onChange={(event) => {
                                                        setRutClient(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="name_client">
                                                <Form.Label column sm="2">Nombre Cliente</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese nombre cliente" required value={name_client} onChange={(event) => {
                                                        setNameClient(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="phone_client">
                                                <Form.Label column sm="2">Telefono</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Telefono" required value={phone_client} onChange={(event) => {
                                                        setPhoneClient(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group as={Row} className="mb-3" controlId="email_client">
                                                <Form.Label column sm="2">Correo</Form.Label>
                                                <Col sm="10">
                                                    <Form.Control type="text" size="lg" placeholder="Ingrese Correo" required value={email_client} onChange={(event) => {
                                                        setEmailClient(event.target.value)
                                                    }} />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                                        Guardar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
