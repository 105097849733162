import React, { useState } from "react";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import SupervisorMenu from "../menu/supervisor.component";
import CoordinationMenu from "../menu/coordination.component";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../logo.png";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

export default function Authentication() {
  const [uName, setUName] = useState("");
  const [uPass, setUPass] = useState("");
  const [validationError, setValidationError] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("uName", uName);
    formData.append("uPass", uPass);

    await axios.post(APIRoute.login, formData).then(({ data }) => {
      if (data.status === 200) {
        sessionStorage.setItem("session", 1);
        sessionStorage.setItem("typeLogin", data.typeLogin);
        sessionStorage.setItem("id", data.id);
        sessionStorage.setItem("token", data.token);
        window.location.reload();
      } else {
        setValidationError("Error de correo electronico o constraseña");
      }
    });
  };

  return (
    <>
      {sessionStorage.getItem("session") === 0 ||
      sessionStorage.getItem("session") === null ? (
        <div className="logo">
          <div
            className="container"
            style={{ marginLeft: "30%", padding: "5%" }}
          >
            <div className="formcontainer2">
              <div className="card" style={{ background: "#F0F0F0" }}>
                <div
                  className="text-center align-items-center"
                  style={{ margin: "20px" }}
                >
                  <h6>Valuaciones de Chile</h6>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      bottom: "10px",
                      right: "10px",
                      width: "100px",
                      margin: 10,
                    }}
                  />
                  <br />
                  <h4>Bienvenido al Sistema de Tasación por Fotografías</h4>
                </div>
                <div className="text-center align-items-center">
                  <h4>Ingrese sus datos</h4>
                  <hr />
                  <div className="form-wrapper">
                    {Object.keys(validationError).length > 0 && (
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-danger">
                            <li>{validationError}</li>
                          </div>
                        </div>
                      </div>
                    )}

                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="userName" className="ml-2 mr-2">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ingrese su nombre de usuario"
                          value={uName}
                          className="form-control input-sm chat-input"
                          onChange={(event) => {
                            setUName(event.target.value);
                          }}
                          style={{ opacity: "1" }}
                        />
                      </Form.Group>
                      <Form.Group controlId="userPassword" className="ml-2 mr-2">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Ingrese su contraseña"
                          value={uPass}
                          className="form-control input-sm chat-input"
                          onChange={(event) => {
                            setUPass(event.target.value);
                          }}
                          style={{ opacity: "1" }}
                        />
                      </Form.Group>
                      <div className="wrapper">
                        <Button
                          variant="primary"
                          className="group-btn"
                          size="lg"
                          block="block"
                          type="submit"
                          style={{ margin: "10%", opacity: "1" }}
                        >
                          Ingresar
                        </Button>
                      </div>
                      <div class="footer text-center">
                        <p>
                          © 2022 Valuaciones de Chile. Todos los Derechos
                          Reservados
                        </p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {sessionStorage.getItem("typeLogin") === "coordinator" ? (
            <CoordinationMenu />
          ) : sessionStorage.getItem("typeLogin") === "supervisor" ? (
            <SupervisorMenu />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
