import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CreateAppraisal from "./create.component";
import CreateAppraisal2 from "./create2.component";
import CreateAppraisal3 from "./create3.component";
import "../../App.css";
import { FiLogOut } from "react-icons/fi";

export function FormUser() {
  const [page, setPage] = useState(0);
  var [confirmValue, setConfirm] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setConfirm(sessionStorage.getItem("statusConfirm"));
    }, 4000);
  }, []);

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <>
          <CreateAppraisal />
        </>
      );
    } else if (page === 1) {
      return (
        <>
          <CreateAppraisal2 />
        </>
      );
    } else if (page === 2) {
      return (
        <>
          <CreateAppraisal3 />
        </>
      );
    }
  };
  return (
    <div className="header my-3">
      <div className="body">{PageDisplay()}</div>
      <div className="footer my-3">
        {page !== 3 ? (
          <>
            <Button
              className="btn btn-outline-primary"
              disabled={page === 0 || page === 1}
              onClick={() => {
                setPage((currPage) => currPage - 1);
              }}
            >
              Anterior
            </Button>
            {page !== 2 ? (
              <Button
                className="btn btn-primary"
                disabled={confirmValue ? false : true}
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                Siguiente
              </Button>
            ) : (
              <Button>
                <a href="/" className="text-white">
                  {sessionStorage.getItem("session") !== 0 ||
                  sessionStorage.getItem("session") != null ? (
                    <div
                      className="logout text-white"
                      onClick={(e) => {
                        sessionStorage.removeItem("session");
                        sessionStorage.removeItem("typeLogin");
                        sessionStorage.removeItem("idUser");
                        sessionStorage.removeItem("idAppraisal");
                        sessionStorage.removeItem("statusConfirm");
                      }}
                    >
                      <FiLogOut size={20} className="float-right text-white" />
                      Finalizar tasación
                    </div>
                  ) : (
                    <></>
                  )}
                </a>
              </Button>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default FormUser;
