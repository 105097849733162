import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import Swal from "sweetalert2";
import { MDBDataTableV5 } from "mdbreact";
import { FiEye } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";

export default function AppraisalList() {
  const [appraisalList, setAppraisalList] = useState([]);
  const headCells = [
    {
      label: "Dirección",
      field: "address",
    },
    {
      label: "Rol",
      field: "rol",
    },
    {
      label: "Superficie terreno",
      field: "terrainArea",
    },
    {
      label: "Área en construcción",
      field: "constructionArea",
    },
    {
      label: "Descipción",
      field: "clientDesc",
    },
    {
      label: "Estado",
      field: "status",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: headCells,
    rows: [],
  });
  useEffect(() => {
    fetchAppraisal();
  }, [APIRoute.appraisal]);

  const fetchAppraisal = async () => {
    await axios.get(APIRoute.appraisal).then(({ data }) => {
      setAppraisalList(data);
      setDatatable({
        columns: headCells,
        rows: data.map((appraisal) => {
          let status = "";
          if (appraisal.status === 1) {
            status = "REALIZADA";
          } else {
            status = "PENDIENTE";
          }
          return {
            id: appraisal.id_appraisal,
            address: appraisal.address,
            rol: appraisal.rol,
            terrainArea: appraisal.terrain_area,
            constructionArea: appraisal.construction_area,
            clientDesc: appraisal.client_description,
            status: status,
            actionBtn: (
              <>
                <div
                  className="d-grid col-md-6 col-lg-9 col-sm-3"
                  style={{ fontSize: "12px" }}
                >
                  <Link to={APIRoute.editAppraisal + appraisal.id_appraisal}>
                    <Button title="Editar" className="btn btn-success">
                      <FiEye style={{ margin: 0, fontSize: "20px" }} />
                    </Button>
                  </Link>
                  <Button
                    title="Eliminar"
                    className="btn btn-danger"
                    onClick={() => deleteClientlist(appraisal.id_appraisal)}
                  >
                    <MdDeleteForever style={{ margin: 0, fontSize: "20px" }} />
                  </Button>
                </div>
              </>
            ),
          };
        }),
      });
    });
  };
  const deleteClientlist = async (id) => {
    const isConfirm = await Swal.fire({
      title: "¿Estas seguro?",
      text: "No podras revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    await axios
      .delete(APIRoute.appraisal + id)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchAppraisal();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            searchTop
            searchBottom={false}
            searchLabel="Busqueda general"
            exportToCSV
            responsive
            data={datatable}
          />
        </div>
      </div>
    </div>
  );
}
