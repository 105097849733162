import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import APIRoute from "../routersAPI/routes.json";
import { useNavigate } from "react-router-dom";

export default function CreateClient() {
  const navigate = useNavigate();
  const [rut_client, setRutClient] = useState("");
  const [name_client, setNameClient] = useState("");
  const [email_client, setEmailClient] = useState("");
  const [phone_client, setPhoneClient] = useState("");
  const [idCoordinator] = useState(sessionStorage.getItem("id"));
  const [type_of_assets, setTypeOfAsset] = useState("");
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [address_client, setAddressClient] = useState("");
  const [commune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [checkListTypeOfAssetsList, setCheckListTypeOfAssetsList] = useState(
    []
  );
  const [rol, setRol] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});

  const fetchRegionList = async () => {
    await axios.get(APIRoute.region).then(({ data }) => {
      setRegionList(data);
    });
  };

  useEffect(() => {
    fetchTypeOfAssetList();
    fetchRegionList();
  }, []);

  const fetchTypeOfAssetList = async () => {
    await axios.get(APIRoute.asset).then(({ data }) => {
      setTypeOfAssetList(data);
    });
  };

  const handleAsset = async (e) => {
    setTypeOfAsset(e);
    await axios
      .get(APIRoute.complement + e)
      .then(({ data }) => {
        setCheckListTypeOfAssetsList(data);
      })
      .catch(({ response }) => {
        setErrorMessage(response.data.errors);
      });
  };

  const handleCommune = async (e) => {
    const formData = new FormData();
    formData.append("id", e);
    await axios
      .post(APIRoute.commune, formData)
      .then(({ data }) => {
        setCommuneList(data);
      })
      .catch(({ response }) => {
        setErrorMessage(response.data.errors);
      });
  };

  const createClient = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("rut", rut_client);
    formData.append("name", name_client);
    formData.append("phone", phone_client);
    formData.append("email", email_client);
    await axios
      .post(APIRoute.clients, formData)
      .then(({ data }) => {
        createAccessKey(data.id_client["id_client"]);
      })
      .catch(({ response }) => {
        if (response.status) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };

  const createAccessKey = (id_client) => {
    const formData = new FormData();
    formData.append("idCoordinator", idCoordinator);
    axios
      .post(APIRoute.code, formData)
      .then(({ data }) => {
        createAppraisal(data.access_code["id_access_code"], id_client);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };

  const createAppraisal = (access_code, id_client) => {
    const formData = new FormData();
    formData.append("idClient", id_client);
    formData.append("idCoordinator", idCoordinator);
    formData.append("idTypeOfAsset", type_of_assets);
    formData.append("idAccessCode", access_code);
    formData.append("idCommune", commune);
    formData.append("address", address_client);
    formData.append("rol", rol);
    axios
      .post(APIRoute.appraisal, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        sendEmail(data.id_appraisal["id_appraisal"]);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };

  const sendEmail = async (id_appraisal) => {
    const formData = new FormData();
    formData.append("id_appraisal", id_appraisal);
    await axios
      .post(APIRoute.send, formData)
      .then(({ data }) => {
        navigate("/client");
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Ingresar Datos Cliente</h4>
              <hr />
              <div className="form-wrapper">
                <Form onSubmit={createClient}>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="rut_client"
                      >
                        <Form.Label column sm="2">
                          Rut Cliente
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="ej. 12345678-9"
                            required
                            value={rut_client}
                            onChange={(event) => {
                              setRutClient(event.target.value);
                              Object.keys(errorMessage).length > 0 && (
                                <div className="row">
                                  <div className="col-12">
                                    <div className="alert alert-danger">
                                      <ul className="mb-0">
                                        {Object.entries(errorMessage).map(
                                          ([key, value]) => (
                                            <li key={key}>{value}</li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="name_client"
                      >
                        <Form.Label column sm="2">
                          Nombre Cliente
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese nombre cliente"
                            required
                            value={name_client}
                            onChange={(event) => {
                              setNameClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="phone_client"
                      >
                        <Form.Label column sm="2">
                          Teléfono
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese Telefono"
                            required
                            value={phone_client}
                            onChange={(event) => {
                              setPhoneClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="email_client"
                      >
                        <Form.Label column sm="2">
                          Correo
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese Correo"
                            required
                            value={email_client}
                            onChange={(event) => {
                              setEmailClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="direccion_client"
                      >
                        <Form.Label column sm="2">
                          Dirección
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="Ingrese Dirección"
                            value={address_client}
                            onChange={(event) => {
                              setAddressClient(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="region">
                        <Form.Label column sm="2">
                          Región
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            aria-label="Default select example"
                            value={region}
                            onChange={(event) => {
                              setRegion(event.target.value);
                              handleCommune(event.target.value);
                            }}
                          >
                            <option value="" defaultValue disabled="disabled">
                              Seleccione Región
                            </option>
                            {regionList.map((item, index) => (
                              <option key={index} value={item.id_region}>
                                {item.name_region}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="commune"
                        name="commune"
                      >
                        <Form.Label column sm="2">
                          Comuna
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            aria-label="Default select example"
                            value={commune}
                            onChange={(event) => {
                              setCommune(event.target.value);
                            }}
                          >
                            <option value="" defaultValue disabled="disabled">
                              Seleccione tipo de comuna
                            </option>
                            {communeList.map((item, index) => (
                              <option key={index} value={item.id_commune}>
                                {item.name_commune}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="type_of_assets"
                      >
                        <Form.Label column sm="2">
                          Tipo de bien
                        </Form.Label>
                        <Col sm="10">
                          <Form.Select
                            aria-label="Default select example"
                            value={type_of_assets}
                            onChange={(event) => {
                              handleAsset(event.target.value);
                            }}
                          >
                            <option value="" defaultValue disabled="disabled">
                              Seleccione tipo de bien
                            </option>
                            {typeOfAssetList.map((item, index) => (
                              <option
                                key={index}
                                value={item.id_type_of_assets}
                              >
                                {item.description}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <Form.Group as={Row} className="mb-3" controlId="rol">
                        <Form.Label column sm="2">
                          Rol
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            type="text"
                            size="lg"
                            placeholder="ej. 123-1234"
                            required
                            value={rol}
                            onChange={(event) => {
                              setRol(event.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    className="mt-2"
                    size="lg"
                    block="block"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
